import { qsa } from "/js/utils/dom"

function toggleNlWidget(e) {
  const widget = e.target.closest(".newsletter-widget")

  if (widget) {
    widget.classList.toggle("widget-expanded")
  }

  const toggle = e.target.closest("[data-newsletter-widget-toggle]")
  if (toggle) {
    toggle.setAttribute(
      "aria-expanded",
      `${!(toggle.getAttribute("aria-expanded") === "true")}`,
    )
  }
}

export function initNewsletterWidgetToggle() {
  for (const widgetToggle of qsa("[data-newsletter-widget-toggle]")) {
    widgetToggle.addEventListener("click", toggleNlWidget)
  }
}
