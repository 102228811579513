/* globals htmx */

export function initProToggle() {
  const toggleButtons = document.querySelectorAll(".pro-toggle__button")

  function clearFilters() {
    const form = document.querySelector(".film-list")
    if (form) {
      const filtersToClear = form.querySelectorAll(
        ".guest-display input, .pro-display input, input[name='sorting'], .info-selection input",
      )

      if (filtersToClear.length > 0) {
        filtersToClear.forEach((filterInput) => {
          if (filterInput.type === "checkbox") filterInput.checked = false
          else filterInput.value = ""

          const parent = filterInput.parentElement
          const label = parent.querySelector("label")

          if (label) {
            label.classList.remove(".filled")
          }

          const button = parent.querySelector(".button-close")
          if (button) {
            button.remove()
          }

          filterInput.classList.remove(".has-content")
        })

        htmx.trigger(filtersToClear[0], "input")
      }
    }
  }

  function togglePro() {
    document.body.classList.toggle("is-pro")

    if (localStorage.getItem("is-pro")) localStorage.removeItem("is-pro")
    else localStorage.setItem("is-pro", true)
  }

  toggleButtons.forEach((toggleButton) => {
    toggleButton.addEventListener("click", () => {
      clearFilters()
      togglePro()
    })
  })
}
