import VimeoPlayer from "@vimeo/player"

let originalHeight = 0

function timeToPlaytime(currentTime) {
  const currentTimeSeconds = Math.floor(currentTime) % 60
  const currentTimeMinutes = Math.floor(currentTime / 60)
  return `${currentTimeMinutes < 10 ? "0" : ""}${currentTimeMinutes}:${
    currentTimeSeconds < 10 ? "0" : ""
  }${currentTimeSeconds}`
}

function iframeResize(playerEl) {
  const iframe = playerEl.querySelector("iframe")
  iframe.width = playerEl.clientWidth
  iframe.height = !document.fullscreenElement
    ? originalHeight
    : playerEl.clientHeight
}

export function initVimeoPlayer() {
  const DEFAULT_OPTIONS = {
    portrait: false,
    controls: false,
    dnt: true,
    byline: false,
  }

  const playerEls = document.querySelectorAll(".vimeo-player")
  async function initPlayer(playerEl) {
    const player = new VimeoPlayer(playerEl, {
      id: playerEl.dataset.trailerId,
      width: playerEl.clientWidth,
      ...DEFAULT_OPTIONS,
    })

    const progressControl = playerEl.querySelector(".progress-control")
    const progressControlProgress = progressControl.querySelector("progress")
    const progressControlTimePlayed =
      progressControl.querySelector("[data-time-played]")
    const progressControlTimeToPlay =
      progressControl.querySelector("[data-time-toplay]")
    const duration = await player.getDuration()

    async function progressUpdate(max = undefined) {
      if (max) progressControlProgress.setAttribute("max", max)

      const currentTime = await player.getCurrentTime()
      progressControlTimePlayed.textContent = timeToPlaytime(currentTime)
      progressControlTimeToPlay.textContent = `-${timeToPlaytime(
        Math.floor(duration - currentTime),
      )}`

      progressControlProgress.setAttribute("value", currentTime)
    }

    progressUpdate(duration)
    let progressUpdateInterval = null

    player.on("play", () => {
      playerEl.classList.add("playing")
      progressUpdateInterval = setInterval(progressUpdate, 1000)
      if (!playerEl.classList.contains("played"))
        playerEl.classList.add("played")
    })

    player.on("pause", () => {
      playerEl.classList.remove("playing")
      clearInterval(progressUpdateInterval)
      progressUpdateInterval = null
    })

    player.on("ended", () => {
      clearInterval(progressUpdateInterval)
      progressUpdateInterval = null
    })

    player.ready().then(() => {
      originalHeight = playerEl.clientHeight
    })

    playerEl.addEventListener("click", (e) => {
      const target = e.target
      if (target) {
        if (target.closest(".progress-control")) {
          const progressControlRect =
            progressControlProgress.getBoundingClientRect()
          const clickedTime =
            (duration * (e.clientX - progressControlRect.left)) /
            progressControlProgress.offsetWidth
          player.setCurrentTime(Math.min(duration, Math.max(0, clickedTime)))
          progressUpdate()
        } else if (playerEl.classList.contains("playing")) {
          const closestButton = target.closest(".button")
          if (closestButton && closestButton.dataset.fullscreen !== undefined) {
            if (!document.fullscreenElement) {
              playerEl.requestFullscreen()
            } else {
              document.exitFullscreen()
            }
          } else {
            player.pause()
          }
        } else player.play()
      }
    })

    player.on("fullscreenchange", () => {
      iframeResize(playerEl)
    })

    let controlsInvisibleTimeout = null
    playerEl.addEventListener("pointermove", () => {
      if (!playerEl.classList.contains("controls-visible"))
        playerEl.classList.add("controls-visible")
      clearTimeout(controlsInvisibleTimeout)
      controlsInvisibleTimeout = setTimeout(() => {
        playerEl.classList.remove("controls-visible")
      }, 2000)
    })
  }
  for (const playerEl of playerEls) {
    initPlayer(playerEl)
  }
}
