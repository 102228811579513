/* global htmx */
/* global gettext */

// import Choices from "choices.js"
//
// export function initChoices() {
//   const choiceFields = document.querySelectorAll(".choice-field")
//   choiceFields.forEach((field) => {
//     const choices = new Choices(field)
//     console.log(choices)
//   })
// }
//

// export function initArticleFilters() {
//   const filterInputs = document.querySelectorAll(".article-filter input")
//
//   const uncheckOthers = (source) => {
//     filterInputs.forEach((input) =>
//       input != source ? (input.checked = false) : true,
//     )
//   }
//
//   filterInputs.forEach((input) => {
//     input.addEventListener("change", (_e) => {
//       uncheckOthers(input)
//     })
//   })
// }

const filterLabel = () => {
  const filterInputs = document.querySelectorAll(".field--filter input")

  filterInputs.forEach((input) => {
    const label = input.parentElement.getElementsByTagName("label")[0]
    const parent = input.parentElement
    const closeButton = document.createElement("button")
    closeButton.classList.add("button", "button-close")
    closeButton.addEventListener(
      "click",
      (e) => {
        e.preventDefault()
        input.value = ""
        htmx.trigger(input, "input")
      },
      false,
    )

    if (input.value) {
      label.classList.add("filled")
      input.classList.add("has-content")
      parent.append(closeButton)
    } else {
      label.classList.remove("filled")
      input.classList.remove("has-content")
      closeButton.remove()
    }

    const checkFilled = (e) => {
      if (e.target.value) {
        label.classList.add("filled")
        input.classList.add("has-content")
        parent.append(closeButton)
      } else {
        label.classList.remove("filled")
        input.classList.remove("has-content")
        closeButton.remove()
      }
    }

    input.addEventListener("input", checkFilled)
  })
}

const filterSort = () => {
  const sortButtons = document.querySelectorAll("[data-sort]")
  const sortSelect = document.getElementById("id_sorting")

  sortButtons.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault()
      const data = e.currentTarget.getAttribute("data-sort")

      if (sortSelect.value === `-${data}`) {
        sortSelect.value = `${data}`
      } else if (sortSelect.value === `${data}`) {
        sortSelect.value = ""
      } else {
        sortSelect.value = `-${data}`
      }
      htmx.trigger("#id_sorting", "input")
    })
  })
}

export function initFilmFilters() {
  filterLabel()
  filterSort()
}

const detailCountry = () => {
  const descriptions = document.querySelectorAll("[data-country-description]")
  descriptions.forEach((oldDescription) => {
    const newDescription = oldDescription.getAttribute(
      "data-country-description",
    )

    if (oldDescription.innerHTML.length < newDescription.length) {
      const moreLink = document.createElement("button")
      moreLink.classList.add("button", "button--link")
      moreLink.innerText = gettext("read more")
      oldDescription.appendChild(moreLink)
      moreLink.addEventListener("click", (e) => {
        e.preventDefault()
        oldDescription.innerHTML = newDescription
      })
    }
  })
}

const detailDirector = () => {
  const filmographies = document.querySelectorAll("[data-filmography]")

  filmographies.forEach((filmography) => {
    const moreLink = document.createElement("button")
    moreLink.classList.add("button", "button--link")
    moreLink.innerText = gettext("filmography")
    filmography.parentElement.appendChild(moreLink)

    moreLink.addEventListener("click", (e) => {
      e.preventDefault()
      filmography.style.display = ""
      moreLink.remove()
    })
  })

  const descriptions = document.querySelectorAll("[data-director-description]")
  descriptions.forEach((oldDescription) => {
    const newDescription = oldDescription.getAttribute(
      "data-director-description",
    )

    if (oldDescription.innerHTML.length < newDescription.length) {
      const moreLink = document.createElement("button")
      moreLink.classList.add("button", "button--link")
      moreLink.innerText = gettext("read more")
      oldDescription.appendChild(moreLink)
      moreLink.addEventListener("click", (e) => {
        e.preventDefault()
        oldDescription.innerHTML = newDescription
      })
    }
  })
}

function clearButtons() {
  const clearButtons = document.querySelectorAll("[data-clear-button]")

  clearButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const targetInputName = button.dataset.clearButton
      const targetInput = document.querySelector(
        `.filter--form input[name=${targetInputName}]`,
      )
      if (targetInput) {
        targetInput.value = ""
        htmx.trigger(targetInput, "input")
      }
    })
  })
}

// function filmSelect() {
//   const selectionForm = document.querySelector("#pro-selection")
//   if (selectionForm) {
// const inputs = selectionForm.querySelectorAll(".film-box input:checked")
// inputs.forEach((input) => {
//   const filmBox = input.closest(".film-box")
//   if (!filmBox.classList.contains("selected"))
//     filmBox.classList.add("selected")
// })
// selectionForm.addEventListener("click", (e) => {
//   const target = e.target.closest(".info__selection input")
//   const filmBox = target ? target.closest(".film-box") : undefined
//   if (target && filmBox) filmBox.setAttribute("hx-preserve", "")
// })
//   }
// }

function keypressHandler() {
  document.body.addEventListener("keydown", (e) => {
    const closestInput = e.target.closest(".field--filter input")
    if (e.key === "Enter" && closestInput) {
      e.preventDefault()
      closestInput.blur()
    }
  })
}

function selectAllButton() {
  document.body.addEventListener("click", (e) => {
    const target = e.target.closest(".select-all__wrap")
    if (target) {
      e.preventDefault()
      const inputs = document.querySelectorAll(
        "#results .info__selection input",
      )
      inputs.forEach((input) => {
        target.hasAttribute("all-checked")
          ? (input.checked = false)
          : (input.checked = true)
      })
      target.toggleAttribute("all-checked")
      checkboxChange(inputs)
    }
  })
}

function checkboxChange(_inputs = undefined) {
  const button = document.querySelector(".button--pro-selection")
  const inputs =
    _inputs || document.querySelectorAll("#results .info__selection input")
  if (Array.from(inputs).some((input) => input.checked))
    button.classList.remove("hide")
  else if (!button.classList.contains("hide")) button.classList.add("hide")
}

function exportSelectionButton() {
  const form = document.querySelector("#pro-selection")

  if (form)
    form.addEventListener("change", (e) => {
      const checkboxTarget = e.target.closest(".info__selection input")
      if (checkboxTarget) {
        checkboxChange()
      }
    })
}

export function initFilmListDetails() {
  detailCountry()
  detailDirector()
  clearButtons()
  keypressHandler()
  selectAllButton()
  exportSelectionButton()
  // filmSelect()
}
